<template lang="pug">
	.dashboard
		Menu(title="KPI Dashboard")

		.container
			template(v-if="isMounted")
				template(v-if="isLoading")
					skeleton
				template(v-else)
					.dashboard__section
						.dashboard__subtitle {{ title }}
						.flex.justify-between
							.dashboard__filters-wrapper.flex.items-center
								.dashboard__filters.flex
									.dashboard__filter(
										v-for="filter of filters"
										:key="filter.value"
										v-model="activeFilter.value"
										:class="{'--active': filter.value === activeFilter.value}"
										@click="changeFilter(filter)"
									)
										| {{ filter.title }}
								OnlyAllCheckbox(
									class="only-all-products-checkbox"
									:value="isOnlyAllProducts"
									label='Display only "All products"'
									@change="isOnlyAllProducts = !isOnlyAllProducts"
								)

							.dashboard__goal(v-if="!isCategories || isCategories && !isEmptyTable")
								GoalFilter(
									:goalsBelowCheckbox="goalsBelowCheckbox"
									:goalsAboveCheckbox="goalsAboveCheckbox"
									@change="changeHandler"
								)
						template(v-if="isLoading")
							.mt-32
								PuSkeleton(height="20px" color="#E0E0E0")
							.mt-16
								PuSkeleton(height="20px" color="#E0E0E0")
							.mt-16
								PuSkeleton(height="20px" color="#E0E0E0")
							.mt-16
								PuSkeleton(height="20px" color="#E0E0E0")
						template(v-else-if="!isDoubledPeriod && response")
							Table.dashboard__table(v-bind="tableOptions" v-if="!isCategories || isCategories && !isEmptyTable")
								template(#percent="{ item, value, col: { name, target, total, passed, enabled } }")
									template(v-if="isActiveGoal(value, target(item[name])) && item[name].enabled")
										Tooltip(
											trigger="clickToToggle"
										)
											template(slot="reference")
												.goal(:class="item[name].enabled ? `--${getGoalClass(value, target(item[name]))}` : '--none'")
													| {{ value }}%
											template()
												div(v-if="name === 'availability'")
													div Количество SKU в наличии
													div Таргет: {{ Math.round(target(item[name])) }} %
													div {{ passed(item[name]) }} из {{ total(item[name]) }} SKU ({{ value }}%) в наличии
												div(v-if="name === 'reviews'")
													div Количество отзывов в карточке
													div Таргет: {{ Math.round(target(item[name])) }} %
													div У {{ passed(item[name]) }} из {{ total(item[name]) }} SKU ({{ value }}%) больше 21 отзыва
												div(v-if="name === 'ratings'")
													div Рейтинг карточки
													div Таргет: {{ Math.round(target(item[name])) }} %
													div У {{ passed(item[name]) }} из {{ total(item[name]) }} SKU ({{ value }}%) рейтинг больше 4.0
												div(v-if="name === 'image'")
													div Корректность изображений
													div Таргет: {{ Math.round(target(item[name])) }} %
													div У {{ passed(item[name]) }} из {{ total(item[name]) }} SKU ({{ value }}%) корректное изображение
												div(v-if="name === 'search'")
													div Процент занятия первых 20 позиций в поиске
													div Таргет: {{ Math.round(target(item[name])) }} %
												div(v-if="name === 'taxonomy'")
													div Процент занятия категорийной выдачи
													div Таргет: {{ Math.round(target(item[name])) }} %
												div(v-if="name === 'content'")
													div Заполненность карточки
													div Таргет: {{ Math.round(target(item[name])) }} %

									template(v-else)
										.goal.--none
											| No data
						template(v-else-if="isDoubledPeriod && response && response2")
							DoubledTable.dashboard__table(v-bind="tableDoubledOptions")
								template(#percent="{ item, value, col: { name, target, total, passed, enabled } }")
									template(v-if="isActiveGoal(value, target(item[name])) && item[name].enabled")
										Tooltip(
											trigger="clickToToggle"
										)
											template(slot="reference")
												.goal.goal-doubled-table(:class="item[name].enabled ? `--${getGoalClass(value, target(item[name]))}` : '--none'")
													| {{ value }}%
											template()
												div Target: {{ Math.round(target(item[name])) }} %
												div(v-if="name === 'availability'")
													| {{ passed(item[name]) }} of {{ total(item[name]) }} products ({{ value }}%) in stock
												div(v-if="name === 'reviews'")
													| {{ passed(item[name]) }} of {{ total(item[name]) }} products ({{ value }}%) had above 21 reviews
												div(v-if="name === 'ratings'")
													| {{ passed(item[name]) }} of {{ total(item[name]) }} products ({{ value }}%) had rating above 4.0
												div(v-if="name === 'image'")
													| {{ passed(item[name]) }} of {{ total(item[name]) }} products ({{ value }}%) had correct image

									template(v-else)
										.goal.goal-doubled-table.--none
											| No data


						template(v-else)
							| Чтобы данные отобразились в дашборде, выберите значения в фильтрах и нажмите "Apply filter”
						
			.empty-filter(v-else) Пожалуйста, выберите значения фильтра

</template>

<script>
import { renameYandex } from '@/helpers/RenameYandexPokupki'
import Menu from '@/components/Menu/Menu.vue'
import Tabs from '@/components/Tabs/Tabs.vue'
import TabItem from '@/components/Tabs/TabItem.vue'
import Table from '@/components/Table/Table.vue'
import DoubledTable from '@/components/DoubledTable/DoubledTable.vue'
import GoalFilter from '@/components/Nestle/GoalFilter/GoalFilter'
import Skeleton from '@/components/Nestle/Skeleton/Common.vue'
import Tooltip from '@/components/Elements/Tooltip.vue'
import OnlyAllCheckbox from "@/components/Nestle/OnlyAllCheckbox.vue";
import FilterInLocalStorage from '@/helpers/LocalStorage/Filter'
import { mapState, mapMutations } from 'vuex'

export default {
	name: 'MainPage',
	components: {
    OnlyAllCheckbox,
		Menu,
		Tabs,
		TabItem,
		DoubledTable,
		Table,
		GoalFilter,
		Skeleton,
		Tooltip,
	},
	props: {
    excludeSku: {
      type: String,
      default: '',
    },
		needUpdateResult: {
			type: Boolean,
			default: false,
		},
		isDoubledPeriod: {
			type: Boolean,
			default: false,
		},
		date: {
			type: Object,
			default: () => {},
		},

		stores: {
			type: String,
			default: '',
		},
		categories: {
			type: String,
			default: '',
		},
		brands: {
			type: String,
			default: '',
		},
    subBrands: {
      type: String,
      default: '',
    },
    seasonProducts: {
      type: String,
			default: '',
		},
		regions: {
			type: String,
			default: '',
		},
		dateFrom: {
			type: String,
			default: '',
		},
		dateTo: {
			type: String,
			default: '',
		},

	},
	data() {
		return {
			goalsBelowCheckbox: {
				high: true,
				medium: true,
				light: true,
			},
			goalsAboveCheckbox: {
				light: true,
				medium: true,
				high: true,
			},
			products: null,
			activeFilter: {
				value: 1,
				title: 'Retailers',
				group: 'retailers',
			},
			filters: [
				{
					value: 1,
					title: 'Retailers',
					group: 'retailers',
				},
				{
					value: 2,
					title: 'Categories',
					group: 'categories',
				},
				{
					value: 3,
					title: 'Brands',
					group: 'brands',
				}
			],
			response: null,
			response2: null,
			isLoading: true,
			isMounted: false,
			dateFromToRequest: null,
			dateToToRequest: null,
			isEmptyTable: false,
			isOnlyAllProducts: false,
		}
	},
	computed: {
		...mapState({
			secondPeriod: ({ globalFilter }) => globalFilter.secondPeriod,
		}),
		isCategories() {
			return this.activeFilter.value === 2;
		},
		title() {
			if (!this.secondPeriod?.from) return 'Latest One Day Score'
			const firstDateFrom = new Intl.DateTimeFormat('ru-RU').format(new Date(this.dateFromToRequest))
			const firstDateTo = new Intl.DateTimeFormat('ru-RU').format(new Date(this.dateToToRequest))

			const secondDateFrom = new Intl.DateTimeFormat('ru-RU').format(new Date(this.secondPeriod.from))
			const secondDateTo = new Intl.DateTimeFormat('ru-RU').format(new Date(this.secondPeriod.to))

			return this.isDoubledPeriod
				? `${firstDateFrom}-${firstDateTo} - ${secondDateFrom}-${secondDateTo}`
				: 'Latest One Day Score'
		},
		tableData() {
			let summary = [{ ...this.response.summary, name: 'All Products'}];

			if (this.isOnlyAllProducts) {
				return [{ ...this.response.summary, name: 'All Products' }];
			} else {
				return summary.concat( this.response.items);
			}
		},
		tableDoubledDataFirst() {
			let summary = [{ ...this.response.summary, name: 'All Products'}]
			return summary.concat( this.response.items)
		},
		tableDoubledDataSecond() {
			let summary = [{ ...this.response2.summary, name: 'All Products'}]
			return summary.concat( this.response2.items)
		},
		tableColumns() {
			return [
				{
					title: '',
					width: 100,
					value: ({ name }) => name,
					enabled: () => {},
				},
				{
					title: 'Portfolio Availability',
					name: 'availability',
					width: 110,
					slot: 'percent',
					value: ({ availability }) => availability.percent || 0,
					enabled: ({ enabled }) => enabled,
					target: ({ target }) => target,
					total: ({ total }) => total,
					passed: ({ passed }) => passed,
				},
				{
					title: 'Reviews',
					name: 'reviews',
					width: 110,
					slot: 'percent',
					value: ({ reviews }) => reviews.percent || 0,
					enabled: ({ enabled }) => enabled,
					target: ({ target }) => target,
					total: ({ total }) => total,
					passed: ({ passed }) => passed,
				},
				{
					title: 'Ratings',
					name: 'ratings',
					width: 110,
					slot: 'percent',
					value: ({ ratings }) => ratings.percent || 0,
					enabled: ({ enabled }) => enabled,
					target: ({ target }) => target,
					total: ({ total }) => total,
					passed: ({ passed }) => passed,
				},
				{
					title: 'Share of Search',
					name: 'search',
					width: 110,
					slot: 'percent',
					value: ({ search }) => search.percent || 0,
					enabled: ({ enabled }) => enabled,
					target: ({ target }) => target,
					total: ({ total }) => total,
					passed: ({ passed }) => passed,
				},
				{
					title: 'Taxonomy Placement',
					name: 'taxonomy',
					width: 110,
					slot: 'percent',
					value: ({ taxonomy }) => taxonomy.percent || 0,
					enabled: ({ enabled }) => enabled,
					target: ({ target }) => target,
					total: ({ total }) => total,
					passed: ({ passed }) => passed,
				},
				{
					title: 'Content Integrity',
					name: 'content',
					width: 110,
					slot: 'percent',
					value: ({ content }) => content.percent || 0,
					enabled: ({ enabled }) => enabled,
					target: ({ target }) => target,
					total: ({ total }) => total,
					passed: ({ passed }) => passed,
				},
				{
					title: 'Image Accuracy',
					name: 'image',
					width: 110,
					slot: 'percent',
					value: ({ image }) => image.percent || 0,
					enabled: ({ enabled }) => enabled,
					target: ({ target }) => target,
					total: ({ total }) => total,
					passed: ({ passed }) => passed,
				},
			]
		},
		tableOptions() {
			return {
				columns: this.tableColumns,
				data: this.tableData,
			}
		},
		tableDoubledOptions() {
			return {
				columns: this.tableColumns,
				data: this.tableDoubledDataFirst,
				data2: this.tableDoubledDataSecond,
			}
		},
	},
	methods: {
		...mapMutations('globalFilter', [
          'setDashboardActiveTab',
    ]),
		getStorage() {
			const defaultStorage = {
				stores: '',
				categories: '',
				brands: '',
        subBrands: '',
				regions: '',
				dateFrom: '',
				dateTo: '',
			}
			return FilterInLocalStorage.get() || defaultStorage;
		},
		getParams() {
			const rawStorage = this.getStorage()

			const result = {
				stores: rawStorage.stores,
				categories: rawStorage.categories,
				brands: rawStorage.brands,
        subBrands: rawStorage.subBrands,
				regions: rawStorage.regions,
        seasonProducts: rawStorage.seasonProducts,
				dateFrom: this.$utils.format(this.$utils.parseISO(rawStorage.dateFrom)) || null,
				dateTo: this.$utils.format(this.$utils.parseISO(rawStorage.dateTo)) || null,
			}
			return result
		},
		async fetch() {
			this.isMounted = true
			this.isEmptyTable = false

			const params = {
				stores: this.stores,
				categories: this.categories,
				brands: this.brands,
        excludeSku: this.excludeSku,
        subBrands: this.subBrands,
				regions: this.regions,
        seasonProducts: this.seasonProducts,
				dateFrom: this.dateFrom,
				dateTo: this.dateTo,
				offset: this.offset,
				limit: this.limit,
				group_by: this.activeFilter.group,
			};

			try {
				this.isLoading = true

				const result = await this.$api.nestle.getKPI(params)

				this.response = result

				if (this.isCategories) {
					const summaryPercent = Object.values(this.response.summary)
						.reduce((acc, item) => {
							acc += item.percent
							return acc
						}, 0)
					if (!summaryPercent) {
						this.isEmptyTable = true
					}

					const truthyItems = this.response.items
						.map((item) => Object.entries(item)
							.some(([key, value]) => {
								if (key === 'search') {
									return value.percent > 0
								}
								return Math.round(value.passed / value.total * 100) > 0
							})
						)

						const newItems = []
						truthyItems.forEach((item, index) => {
							if(item) {
								newItems.push(this.response.items[index])
							}
						})

						result.items = newItems
				}
				
				const renamedItems = renameYandex(result.items)

				this.response.items = renamedItems
			} catch(e) {
				console.log(e)
			} finally {
				this.isLoading = false
			}
		},
		async doubleFetch() {
			this.isMounted = true
			this.isEmptyTable = false
			
			const params = this.getParams()
			params.offset = this.offset
			params.limit = this.limit
			params.group_by = this.activeFilter.group
			this.dateFromToRequest = params.dateFrom
			this.dateToToRequest = params.dateTo
			
			const params2 = JSON.parse(JSON.stringify(params));
			params2.dateFrom = this.secondPeriod.from;
			params2.dateTo = this.secondPeriod.to;

			this.isLoading = true;

			await Promise.all([
				this.$api.nestle.getKPI(params),
				this.$api.nestle.getKPI(params2)
			]).then((values) => {
				this.isLoading = false;

				this.response = values[0]
				const renamedItems = renameYandex(values[0].items)
				this.response.items = renamedItems

				this.response2 = values[1]
				const renamedItems2 = renameYandex(values[1].items)
				this.response2.items = renamedItems2
			}).catch((e) => {
				console.log(e);
			})
		},
		changeFilter(item) {
			this.activeFilter = item
			this.setDashboardActiveTab(item.group)
			this.fetch()
		},
		getGoalClass(val, target) {
			const result = val / target * 100
			let className
			if (result > 122) {
				className = 'above-high'
			} else if (result >= 110 && result <= 121.99) {
				className = 'above-medium'
			} else if (result >= 99 && result <= 109.99) {
				className = 'above-light'
			} else if (result >= 70 && result <= 98.99) {
				className = 'below-light'
			} else if (result >= 33 && result <= 69.99) {
				className = 'below-medium'
			} else {
				className = 'below-high'
			}
			return className
		},
		isActiveGoal(val, target) {
			let params = this.getGoalClass(val, target).split('-')
			return params[0] === 'below' ? this.goalsBelowCheckbox[params[1]] : this.goalsAboveCheckbox[params[1]]
		},
		changeHandler({item, type}) {
			if (type === 'below') {
				this.goalsBelowCheckbox[item.title] = !item.value
			} else {
				this.goalsAboveCheckbox[item.title] = !item.value
			}
		},
	},
	mounted() {
		this.setDashboardActiveTab('retailers')
	},
	watch: {
		needUpdateResult: {
			async handler() {
				if (this.isDoubledPeriod) {
					this.doubleFetch()
				} else {
					await this.fetch()
				}
			}
		},
		current: {
			handler() {
				this.fetch()
			},
		},
	},

}
</script>

<style lang="scss" scoped>
.dashboard {
	&__nav {
		width: 190px;
	}
	&__date {
		width: 100px;
		color: color(white);
		cursor: pointer;
	}
	&__tabs {
		margin: 36px 0;
	}
	&__subtitle {
		font-size: 16px;
		font-weight: 600;
	}
	&__section {
		padding: 20px;
		background-color: color(white);
		border-radius: 4px;
	}
	&__filters {
		margin-top: 20px;
	}

	&__filter {
		padding: 4px 8px;
		border: 1px solid color(gray-300);
		cursor: pointer;

		&.--active {
			background-color: color(gray-200);
		}
	}

	&__table {
		margin-top: 36px;

		tbody {
			tr {
				&:first-of-type {
					height: 80px;
				}
			}
		}
	}

	.goal {
		height: 40px;
		padding: 10px 20px;
		border-radius: 4px;
		text-align: center;
		font-weight: 600;

		&.--none {
			background-color: color(gray-200);
			color: color(gray-700);
			cursor: not-allowed;
		}

		&.--above-high {
			background-color: color(goal-above-high);
		}
		&.--above-medium {
			background-color: color(goal-above-medium);
		}
		&.--above-light {
			background-color: color(goal-above-light);
		}
		&.--below-light {
			background-color: color(goal-below-light);
		}
		&.--below-medium {
			background-color: color(goal-below-medium);
		}
		&.--below-high {
			background-color: color(goal-below-high);
		}
		&-doubled-table {
			max-width: 65px;
			display: flex;
			align-items: center;
			justify-content: center;
			&.--none {
				font-size: 12px;
				line-height: 1.1;
			}
		}
	}
}
.empty-filter {
	font-size: 16px;
    display: flex;
    justify-content: center;
    margin-top: 30px;
}
.star-hint {
	margin-top: 14px;
    display: block;
}
.maintanance {
	display: flex;
	align-items: center;
	margin-top: 20px;
}

.only-all-products-checkbox {
  padding-top: 20px;
  margin-left: 10px;
}

.dashboard__table {
  ::v-deep .td__cell {
    word-break: break-word;
  }
}
</style>
